<template>
  <div>
    <!-- Start Header Area -->
    <Header></Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
        class="breadcrumb-area ptb--60 bg_image bg_dark"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Angebot</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                      item.text
                    }}
                  </router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="pb--80">
                  <v-col class="mb-12">
                    <h2 class="heading-title">Unser Angebot.</h2>
                    <p class="description mt-13">
                      Bei goeldi technology erhältst du das gesamte Package. Gemeinsam mit dir analysieren wir dein
                      Problem und gestalten deine Prozesse effizient und effektiv. Wir entwickeln die passende Software
                      oder Applikation für deine Bedürfnisse und realisieren es durch unser innovatives Design modern
                      und userfreundlich.
                    </p>
                  </v-col>
                </v-row>

                <!-- Start Single Content  -->
                <v-row
                    class="sercice-details-content pb--120 align-items-center"
                    align="center" justify="center"
                >
                  <v-col lg="4" md="4" cols="12" class="">
                    <div class="service-icon">
                      <img :src="require('../assets/images/service/solutions.png')">
                    </div>
                  </v-col>
                  <v-col lg="8" md="8" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h3 class="heading-title">Business Solutions</h3>
                      <p>
                        Uns ist es wichtig, dass wir Lösungen finden, die perfekt auf dein Problem zugeschnitten sind.
                        Deshalb legen wir grossen Wert auf die Applikationsarchitektur. Wir analysieren deine
                        Bedürfnisse und wählen die passenden Ressourcen, Skalierbarkeit und Sicherheit für dein
                        persönliches Projekt. Durch Prozessoptimierungen und moderne Gestaltung stellen wir sicher, dass
                        die Software effizient und userfreundlich ist.
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <v-row
                    class="sercice-details-content pb--120 align-items-center"
                    align="center" justify="center"
                >
                  <v-col lg="4" md="4" cols="12" class="" order-lg="1" order="1">
                    <div class="service-icon">
                      <img :src="require('../assets/images/service/applications.png')">
                    </div>
                  </v-col>
                  <v-col lg="8" md="8" cols="12" order-lg="2" order="2">
                    <div class="details mt_md--30 mt_sm--30">
                      <h3 class="heading-title">Applikationen und Softwareentwicklung </h3>
                      <p>
                        Die Grundlage einer guten Business Applikation liegt in der Entwicklung. Wir starten mit einem
                        einfachen modernen Framework und erweitern es nach deinen persönlichen und individuellen
                        Bedürfnissen. Dabei legen wir den Fokus auf Webapplikationen.
                      </p>

                      <p class="font-weight-bold">Wieso Webapplikationen?</p>
                      <ul class="liststyle bullet mt-4">
                        <li>Zum einen sind sie <strong>flexibel einsetzbar</strong> – sie funktionieren zu Hause am Desktop, in einem
                          Café am Tablet oder unterwegs auf dem Mobile.
                        </li>
                        <li>Durch den multifunktionalen Einsatz von Webapplikationen <strong>sparst du Kosten und Ressourcen</strong>
                        </li>
                        <li>Mittels dem Responsive Design erscheint deine Webapplikation auf allen Endgeräten in einem
                          modernen und trotzdem nutzerfreundlichen Look.
                        </li>
                        <li>Durch den Einsatz von <strong>Progressive Web Apps</strong> kannst du deine Webapplikation gleich wie eine App
                          auf dem Homescreen installieren und sie unabhängig von App Stores nutzen.
                        </li>
                      </ul>

                      <p class="mt-4">
                        Die Entwicklung allein reicht aber nicht. Unser Support und die Wartung stellen sicher, dass
                        deine Applikation Instand bleibt. Wir hosten sie als Cloud Application, auf Microsoft Azure oder
                        on Premise. Durch regelmässige Checks garantieren wir die Sicherheit deiner Daten.
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                    class="sercice-details-content pb--120 align-items-center"
                    align="center" justify="center"
                >
                  <v-col lg="4" md="4" cols="12" class="">
                    <div class="service-icon">
                      <img :src="require('../assets/images/service/consulting.png')">
                    </div>
                  </v-col>
                  <v-col lg="8" md="8" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h3 class="heading-title">Beratung</h3>
                      <p>
                        In der Beratung starten wir mit einer genauen Analyse deines Problems. Im Team erarbeiten wir
                        verschiedenste Lösungsansätze, die wir dir mittels entworfener Prototypen einfach aufzeigen.
                        Durch unser breites Knowhow in der IT stellen wir sicher, dass unsere Lösungen deinen Wünschen
                        entsprechen und technisch realisierbar sind. Ziel unserer Beratung ist, dass du einen guten
                        Überblick über deine Möglichkeiten hast und die Problemlösung mit deinen Ressourcen
                        übereinstimmen.
                      </p>

                      <!--                      <ul class="liststyle bullet mt-4">-->
                      <!--                        <li>Yet this above sewed flirted opened ouch</li>-->
                      <!--                        <li>Goldfinch realistic sporadic ingenuous</li>-->
                      <!--                      </ul>-->
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      items: [
        {
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "Angebot",
          to: "",
          disabled: true,
        }
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style lang="scss">
.details {
  p, li {
    font-weight: 400;
  }
}
.service-icon {
  img {
    max-width: 220px;
    margin: auto;
  }
}
</style>
